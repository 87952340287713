import bedroom1 from '../assets/BedRoom1.jpg';
import bedroom2 from '../assets/Bedroom study area.jpg';
import dining from '../assets/Dining Room.jpg';
import kiving from '../assets/Kiving room sitting.jpg';
import living from '../assets/Living Room.jpg';
import lobby from '../assets/Office Lobby.jpg';
import reception from '../assets/Office Reception.jpg';
import office1 from '../assets/office1.jpg'
import office2 from '../assets/office2.jpg'
import office3 from '../assets/office3.jpg'
import office4 from '../assets/office4.jpg'

import office6 from '../assets/office6.jpg'
import office7 from '../assets/office7.jpg'
import office8 from '../assets/office8.jpg'
import office9 from '../assets/office9.jpg'
import office10 from '../assets/office10.jpg'
import office11 from '../assets/office11.jpg'
import service1 from '../assets/process.png';
import service2 from '../assets/interior-design.png';
import service3 from '../assets/furniture.png';
import service4 from '../assets/ceiling.png';
import service5 from '../assets/kitchen.png';
import service6 from '../assets/plugin.png';
import service7 from '../assets/headphone-mic.png';
import logo from '../assets/loggo.png';
import phone from '../assets/phone.png';
import instagram from '../assets/instagram.png';
import whatsapp from '../assets/whatsapp.png';
import house11 from '../assets/house11.jpg';
import house10 from '../assets/house10.jpg';
import house4 from '../assets/house4.jpg';
import location from '../assets/location.png';
import gmail from "../assets/gmail.png"
import owner from "../assets/owner.jpg"
import owner1 from "../assets/owner1.jpg"
import intVideo from "../assets/intVideo.mp4"


export default {
   intVideo,
   owner,
   owner1,
   location,
   house4,
   house11,
   house10,
   instagram,
   whatsapp,
   phone,
   bedroom1,
   bedroom2,
   dining,
   kiving,
   living,
   lobby,
   reception,
   office1,
   office2,
   office3,
   office4,
   gmail,
   office6,
   office7,
   office8,
   office9,
   office10,
   office11,
   service1,
   service2,
   service3,
   service4,
   service5,
   service6,
   service7,
   logo,
  };