import React, { useState } from "react";
import "./AboutUs.scss";
import { FaGooglePlay } from "react-icons/fa";
import { IoMdPause } from "react-icons/io";
import { images } from "../constants";
const AboutUs = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
    const video = document.getElementById("video");
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
  };
  return (
    <div className="about-us">
      <h3>About Us</h3>
      <div className="div1">
        <div className="div2">
          <p>
            Hello, I am Mahendra Jangir the head interior designer of Dev Interiors. I was born and brought up in Jaipur Rajasthan the city of arts and culture.
            I have learnt art from the skilled people of Rajasthan. I have perfect art required to design your offices, hotels, restaurants, houses, kitchens
            and many more. We at Dev Interiors are the best choice because it requires art, skill and knowledge to create the perfect office, house or anything
            you want. We have attached some of ours perfect artworks you can find it in portfolio. If you have imagined your dream place let us make
            it real for you.{" "}
          </p>
          <div className="imageContainer">
            <img src={images.owner} alt=""></img>
          </div>
        </div>
        <div className="div3">
          <div className="imageContainer2">
            <video className="video2" id="video" src={images.intVideo} type="video/mp4" controls></video>

            {isPlaying ? (
              <IoMdPause className="playPauseButton" onClick={handlePlayPause} />
            ) : (
              <FaGooglePlay className="playPauseButton" onClick={handlePlayPause} />
            )}
          </div>

          <p className="p3">
            We understand the importance of good design and how it can positively impact your daily life. Our design philosophy is centered around creating
            personalized and functional spaces that reflect our clients' unique tastes and needs. We believe that every space has the potential to be beautiful
            and we work closely with our clients to make their design dreams a reality.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
